<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation *ngIf="navigation"
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex flex-col items-center p-4 pb-0">
            <!-- Light version -->
            <img
                class="w-20 dark:hidden"
                src="images/logo/logo-text.svg"
                alt="Logo image"
            />
            <!-- Dark version -->
            <img
                class="hidden w-20 dark:flex"
                src="images/logo/logo-text-on-dark.svg"
                alt="Logo image"
            />
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
    <!-- Header -->
    <div
        class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
    >
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2" >
            <!-- <form [formGroup]="dateRangeForm" *ngIf="showrange">
                <mat-form-field appearance="outline">
                  <mat-label>Date range</mat-label>
                  <mat-date-range-input [formGroup]="dateRangeForm" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Start date">
                    <input matEndDate formControlName="end" placeholder="End date">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </form> -->
                        <!-- <p-calendar 
                [(ngModel)]="rangeDates" 
                selectionMode="range" 
                [readonlyInput]="true" />
  -->
                <div *ngIf="showrange">
                    <mat-label>Date Range</mat-label>
                    <div >
                      <p-calendar 
                        [(ngModel)]="rangeDates" 
                        [showIcon]="true" 
                        [readonlyInput]="true"
                        selectionMode="range" 
                        (onSelect)="Onrangeselect($event)"
                        appendTo="body"
                        dateFormat="dd/mm/yy" 
                      ></p-calendar> 
                    </div>
                  </div>

              <mat-form-field appearance="outline" *ngIf="showdate">
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate"
                       [min]="minDate" [max]="maxDate" [matDatepickerFilter]="dateFilter"
                       (dateChange)="onDateChange($event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              
        
              <div>
                 
                  <div *ngIf="showweek">
                    <mat-label>Week</mat-label>
                    <div >
                      <p-calendar 
                        [(ngModel)]="selectedWeek" 
                        [showIcon]="true" 
                        [showButtonBar]="true" 
                        [selectionMode]="'single'" 
                        (onSelect)="onWeekSelect($event)"
                        appendTo="body"
                        dateFormat="dd/mm/yy" 
                      ></p-calendar> 
                    </div>
                  </div>

                  <div *ngIf="showmonth" >
                    <div class="mb-1">Month</div>
                    <div >
                      <p-calendar 
                      class="border border-gray-600 rounded-lg p-2 mt-2 shadow-lg"
                        [(ngModel)]="selectedMonth" 
                        [showButtonBar]="true" 
                        (onSelect)="onmonthSelect($event)"
                        [showIcon]="true" view="month" dateFormat="mm/yy" 
                      ></p-calendar> 
                    </div>
                  </div>

              </div>
<!-- 
              <a href="https://metahos.bpclcloud9.com/portal-selection" target="_self" *ngIf="user && user.role != 'Dealer' && user.role != 'dealer' && user.role != 'ro namager' && user.role !='RO Manager' && user.role != 'Attendant' && user.role != 'attendant'">
                <button mat-icon-button>
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:home'"></mat-icon>
                </button>
            </a> -->

            <a href="https://metahos.bpclcloud9.com/portal-selection" target="_self" *ngIf="user && user.role != 'Dealer' && user.role != 'dealer' && user.role != 'ro namager' && user.role !='RO Manager' && user.role != 'Attendant' && user.role != 'attendant'">
                <button mat-icon-button>
                    <div class="flex flex-col justify-center items-center">
                  <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:home'"></mat-icon>
                  <span class="text-xs text-secondary">Home</span>
                </div>
                </button>
              </a>

            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col ">
             <div class="relative flex flex-col gap-4 pt-3 px-4 sm:flex-row sm:items-center sm:justify-start sm:gap-2" *ngIf="user && showaccessfilters">
              
                <mat-form-field class="w-full bg-white text-black" appearance="outline" floatLabel="true">
                    <mat-label>State Office</mat-label>
                    <mat-select [(ngModel)]="accesssearch.stateoffice" id="stateoffice" name="stateoffice" 
                        (selectionChange)="addfilter('stateoffice')">
                        <ngx-mat-select-search placeholderLabel="Search State Office"  [formControl]="searchControl1"></ngx-mat-select-search>
                        <mat-option *ngFor="let state of filteredStateOffices" [value]="state">{{ state }}</mat-option>
                    </mat-select>
                </mat-form-field>
            
                <mat-form-field class="w-full bg-white text-black" appearance="outline" floatLabel="true">
                    <mat-label>Territory</mat-label>
                    <mat-select [(ngModel)]="accesssearch.territory" id="territory" name="territory"
                        (selectionChange)="addfilter('territory')">
                        <ngx-mat-select-search placeholderLabel="Search Territory"  [formControl]="searchControl2"></ngx-mat-select-search>
                        <mat-option *ngFor="let territory of filteredterritories" [value]="territory">{{ territory }}</mat-option>
                    </mat-select>
                </mat-form-field>
            
                <mat-form-field class="w-full bg-white text-black" appearance="outline" floatLabel="true">
                    <mat-label>Sales Area</mat-label>
                    <mat-select [(ngModel)]="accesssearch.salesarea" id="salesarea" name="salesarea"
                        (selectionChange)="addfilter('salesarea')">
                        <ngx-mat-select-search placeholderLabel="Search Sales Area" [formControl]="searchControl3"></ngx-mat-select-search>

                        <mat-option *ngFor="let salesArea of filteredsalesAreas" [value]="salesArea">{{ salesArea }}</mat-option>
                        
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-full bg-white text-black" appearance="outline" floatLabel="true">
                    <mat-label>RO</mat-label>
                    <mat-select [(ngModel)]="accesssearch.ro" id="ro" name="ro"
                        (selectionChange)="addfilter('ro')" >
                        <ngx-mat-select-search placeholderLabel="Search RO"  [formControl]="searchControl4"></ngx-mat-select-search>
                        <mat-option *ngFor="let roid of filteredroids" [value]="roid">{{ roid }}</mat-option>
                    </mat-select>
                </mat-form-field>
                
            </div>
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>

    <!-- Footer -->
    <div
        class="bg-card relative z-49 flex h-14 w-full flex-0 items-center justify-start border-t px-4 dark:bg-transparent md:px-6 print:hidden"
    >
        <span class="text-secondary font-medium"
            >IOTHOS &copy; {{ currentYear }}</span
        >
    </div>
</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
