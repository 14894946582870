import { BooleanInput } from '@angular/cdk/coercion';
import { CommonModule, NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.services';
import { AuthenticationService } from 'app/auth-services/authentication.service';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { KeycloakService } from 'keycloak-angular';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        NgClass,
        MatDividerModule,
    ],
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: User;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private _bs: BaseRequestService,
        private readonly keycloak: KeycloakService,
        private cs: CommonService,
        private authenservice:AuthenticationService
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user changes
        this._userService.userd$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = JSON.parse(JSON.stringify(user));
                if (this.user && this.user.role) {
                    this.user.role = this.user.role.toUpperCase();
                }
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._userService
            .update({
                ...this.user,
                status,
            })
            .subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        // this._router.navigate(['/sign-out']).then(r => console.log(r));
        if(this.user.role.toLowerCase() != 'ro manager' && this.user.role.toLowerCase() != 'dealer' && this.user.role.toLowerCase() != 'attendant'){
            this.authenservice.logoutmetahos()
        }else{
            this.authenservice.signOut()
        }
        
    }

    async getRoles(user: any): Promise<any> {
        try {
            const response = await this._bs.doRequest(`/api/data/hos_role`, 'post', {}).toPromise();
            if (response.status) {
                const results = response.data.filter((x: any) => x.email === user['email']);
                if (results && results.length) {
                } else {
                }
            } else {
            }
        } catch (error) {
            // Handle errors here
            console.log(error);

            throw error;
        }
    }
}
