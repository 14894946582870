import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { CommonService } from 'app/_services/common.services';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core'; // Native date module for date handling
import { MaterialModule } from 'app/material.module';
import { CommonModule } from '@angular/common';
import { BaseRequestService } from 'app/_services/base.service';
import { MatSelectModule } from '@angular/material/select';
import { FormControl } from '@angular/forms';
import { UserService } from 'app/core/user/user.service';
import { CalendarModule } from 'primeng/calendar';
import { MatFormFieldModule } from '@angular/material/form-field';
@Component({
    selector: 'classic-layout',
    templateUrl: './classic.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        FuseVerticalNavigationComponent,
        MatButtonModule,
        MatIconModule,
        NgxMatSelectSearchModule,
        MatSelectModule,
        LanguagesComponent,
        FuseFullscreenComponent,
        SearchComponent,
        ShortcutsComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        RouterOutlet,
        MatFormFieldModule,
        FormsModule,
        QuickChatComponent,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        MaterialModule,
        CommonModule,
        CalendarModule
    ],
    styleUrl: './classic.component.scss'

})
export class ClassicLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    isScreenSmall: boolean;
    navigation: Navigation;
    _overall_navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    dateRangeForm: FormGroup;
    showrange:boolean=false
    showweek:false;
    selectedDate: Date | null = null;
    showdate:boolean=false
    accesssearch={"territory":"", "stateoffice":"", "salesarea":"", "ro":""}
    dropdowndata: any;
    stateOffices: unknown[];
    territories: any[];
    salesAreas: any[];
    roids: any[];
    filteredStateOffices: unknown[];
    filteredterritories: unknown[];
    filteredsalesAreas: unknown[];
    filteredroids: unknown[];
    searchControl1 = new FormControl();
    searchControl2 = new FormControl();
    searchControl3 = new FormControl();
    searchControl4 = new FormControl();
    user: any;
    showaccessfilters:boolean=false
    selecteddate: Date;
    selectedMonth: Date;
    selectedWeek: Date;
    showmonth:boolean=false
    rangeDates:null
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _cs: CommonService,
        private fb: FormBuilder,
        private cd:ChangeDetectorRef,
        private userservice:UserService,
        private baseservice:BaseRequestService
    ) {
        this.searchControl1.valueChanges.subscribe(value => {
            this.filterMyOptions(value, 'stateoffice');
        });
        this.searchControl2.valueChanges.subscribe(value => {
            this.filterMyOptions(value, 'territory');
        });
        this.searchControl3.valueChanges.subscribe(value => {
            this.filterMyOptions(value, 'salesarea');
        });
        this.searchControl4.valueChanges.subscribe(value => {
            this.filterMyOptions(value, 'ro');
        });
        this.dateRangeForm = this.fb.group({
            start: [],
            end: []
          });

          this._cs.rangeChange.subscribe(values => {
            this.showrange = values
            this.cd.detectChanges();
          })

          this._cs.dateChange.subscribe(values => {
            this.showdate = values
            this.cd.detectChanges();
          })
          this._cs.showaccessfilter.subscribe(values =>{
            this.showaccessfilters = values
            this.cd.detectChanges()
          })
          this._cs.showmonth.subscribe(values =>{
            this.showmonth = values
            this.cd.detectChanges()
          })
         
    }
    ngAfterViewInit(): void {
        // throw new Error('Method not implemented.');
        this._navigationService.navigation$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((navigation: Navigation) => {
            this.navigation = navigation;
        });

    // Subscribe to media changes

    this._fuseMediaWatcherService.onMediaChange$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(({ matchingAliases }) => {
            // Check if the screen is small
            this.isScreenSmall = !matchingAliases.includes('md');
        });

        setTimeout(() => {
            try{
            this.userservice.userd$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: any) => {
                this.user = user;
        
            });}catch (error) {
               if(!this.user){
                this.user = JSON.parse(localStorage.getItem('user'))
               }
                
            }
    
        this.getrodropdown() 
        }, 1000);
        
    }

    getStartOfWeek(date: Date): Date {
        const day = date.getDay();
        const diff = date.getDate() - day; 
        return new Date(date.setDate(diff));
      }
    
      onWeekSelect(event: any) {
        const startOfWeek = this.getStartOfWeek(event);
        console.log('Start of Week:', startOfWeek);
      }

      onmonthSelect(event:any){
        const month = ('0' + (event.getMonth() + 1)).slice(-2); 
        const year = event.getFullYear(); 
        var d= `${year}-${month}`; 
        console.log('Month: qw', d);
        this._cs.monthpicker(d) 
      }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    onDateChange(event: any): void {
        this._cs.datesearch(event)
        console.log('Selected date:', this.selectedDate);

      }

      Onrangeselect(){
        console.log(this.rangeDates)
        this._cs.selectedrange(this.rangeDates)
      }
      
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        // this._navigationService.navigation$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((navigation: Navigation) => {
        //         this.navigation = navigation;
        //     });

        // // Subscribe to media changes
        // this._fuseMediaWatcherService.onMediaChange$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(({ matchingAliases }) => {
        //         // Check if the screen is small
        //         this.isScreenSmall = !matchingAliases.includes('md');
        //     });

        //     this.userservice.userd$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((user: any) => {
        //         this.user = user;
        
        //     });

        // this.getrodropdown()
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }


    getrodropdown(){
        this.baseservice
        .doRequest('/get_queries/get_ro_cache', 'get', null, {send_fields:true})
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
            (res: any) => {
                this.dropdowndata = res.data
                this.stateOffices = [...new Set(res.data.map(item => item.StateOffice))];
                this.filteredStateOffices = [...new Set(res.data.map(item => item.StateOffice))]
                this.territories = [...new Set(res.data.map(item => item.Territory))];
                this.filteredterritories = [...new Set(res.data.map(item => item.Territory))]
                this.salesAreas = [...new Set(res.data.map(item => item.salesarea))];
                this.filteredsalesAreas = [...new Set(res.data.map(item => item.salesarea))]
                this.roids = [...new Set(res.data.map(item => item.ROID))];
                this.filteredroids = [...new Set(res.data.map(item => item.ROID))]
            })        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    addfilter(data){
        console.log("data changed", data)
        if(data == 'stateoffice'){
            this.territories = [...new Set(this.dropdowndata
                .filter(item => item.StateOffice === this.accesssearch.stateoffice)
                .map(item => item.Territory))];
            this.filteredterritories = [...new Set(this.dropdowndata
                .filter(item => item.StateOffice === this.accesssearch.stateoffice)
                .map(item => item.Territory))];
          
            this.roids = [];
            
        }else if(data == 'territory'){
            this.salesAreas = [...new Set(this.dropdowndata
                .filter(item => item.StateOffice === this.accesssearch.stateoffice && item.Territory === this.accesssearch.territory)
                .map(item => item.salesarea))];
            this.filteredsalesAreas = [...new Set(this.dropdowndata
                    .filter(item => item.StateOffice === this.accesssearch.stateoffice && item.Territory === this.accesssearch.territory)
                    .map(item => item.salesarea))];
            
        }
        else if(data == 'salesarea'){
            this.roids = this.dropdowndata
            .filter(item => item.StateOffice === this.accesssearch.stateoffice && item.Territory === this.accesssearch.territory && item.salesarea === this.accesssearch.salesarea)
            .map(item => item.ROID);
            this.filteredroids = this.dropdowndata
            .filter(item => item.StateOffice === this.accesssearch.stateoffice && item.Territory === this.accesssearch.territory && item.salesarea === this.accesssearch.salesarea)
            .map(item => item.ROID);
            
        }
        this._cs.accesssearchcs(this.accesssearch)
    }

    filterMyOptions(value, field){
        if(value.length >0){
        if(field == 'stateoffice'){
            this.filteredStateOffices = this.stateOffices.filter((item:any) => 
                item.toLowerCase().includes(value.toLowerCase())
            );
                    }
        if(field == 'territory'){
            this.filteredterritories = this.territories.filter(item => 
                item.toLowerCase().includes(value.toLowerCase())
            );
        }
        if(field == 'salesarea'){
            this.filteredsalesAreas = this.salesAreas.filter(item => 
                item.toLowerCase().includes(value.toLowerCase())
            );
        }
        if(field == 'ro'){
            this.filteredroids = this.roids.filter(item => 
                item.toString().includes(value) // Assuming ROIDs are numbers, convert to string for comparison
            );
        }}else{
            this.filteredStateOffices = [...this.stateOffices]
            this.filteredterritories =  [...this.territories]
            this.filteredsalesAreas =  [...this.salesAreas]
            this.filteredroids = [...this.roids]
        }
    }

}

